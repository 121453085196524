import Vue from 'vue';
import VueRouter from 'vue-router';

import authService from '../auth/authService';

Vue.use(VueRouter);

const defaultPath = '/';
const getDefaultPath = () => defaultPath;
const loginPath = '/login';
const signUpPath = '/sign-up';
const forgotPassword = '/forgot-password';
const twoFactorLogin = '/two-factor-login';
const phoneIssue = '/phone-issue';
const recoverAccount = '/recover-account';
const recoverPassword = '/recover-password/:token';
const unauthorizedPath = '/unauthorized';
const noticePath = '/notice';
export const userConfigurationPath = '/user-configuration';
const tagConfigurationPath = '/tag-configuration';
const tagManualPath = '/tag-manual';

const routes = [
    {
        path: loginPath,
        component: () => import('@/views/login/Login.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: twoFactorLogin,
        component: () => import('@/views/login/TwoFactorLogin.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: phoneIssue,
        component: () => import('@/views/login/PhoneIssue.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: recoverAccount,
        component: () => import('@/views/login/RecoverAccount.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: forgotPassword,
        component: () => import('@/views/forgot-password/ForgotPassword.vue'),
        meta: {
            requiresAnnonymousUser: true,
            public: true,
        }
    },
    {
        path: recoverPassword,
        component: () => import('@/views/forgot-password/RecoverPassword.vue'),
        meta: {
            requiresAnnonymousUser: true,
            public: true,
        }
    },
    {
        path: signUpPath,
        component: () => import('@/views/signUp/SignUp.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    // Home
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/Home.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'Inicio'
                }
            ]
        }
    },
    // Reportes
    {
        path: '/reporting',
        name: 'Reporting',
        component: () => import('@/views/reporting/Reporting.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'Reporte / Etiqueta'
                }
            ]
        }
    },
    // Etiqueta
    {
        path: '/view/:hash',
        name: 'QR',
        component: () => import('@/views/qr/Qr.vue'),
        meta: {
            hideNavbar: true,
            public: true,
        }
    },
    // Etiqueta publica San Mateo
    {
        path: '/public/view/a',
        name: 'QR a',
        component: () => import('@/views/qr-public/Qr-san-mateo.vue'),
        meta: {
            hideNavbar: true,
            public: true,
        }
    },
    // Avisos
    {
        path: noticePath,
        name: 'NoticeHome',
        component: () => import('@/views/notice/NoticeHome.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'Avisos'
                }
            ]
        }
    },
    {
        path: noticePath + '/notice-by-brand/:brandId',
        name: 'NoticeByBrand',
        component: () => import('@/views/notice/NoticeByBrand.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'Avisos'
                }
            ]
        }
    },
    // API Key
    {
        path: '/api-key',
        name: 'ApiKeyHome',
        component: () => import('@/views/apiKey/ApiKeyHome.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'API Key'
                }
            ]
        }
    },
    // // Tag Configuration
    // {
    //     path: tagConfigurationPath,
    //     name: 'tagConfigurationHome',
    //     component: () => import('@/views/tag-configuration/ConfigurationHome.vue'),
    //     meta: {
    //         breadCrumb: [
    //             {
    //                 text: 'inicio / Configuracion de etiqueta'
    //             }
    //         ]
    //     }
    // },
    // // Etiqueta manual
    // {
    //     path: tagManualPath,
    //     name: 'tagManual',
    //     component: () => import('@/views/tag-manual/TagManualHome.vue'),
    //     meta: {
    //         breadCrumb: [
    //             {
    //                 text: 'Etiqueta manual'
    //             }
    //         ]
    //     }
    // },
    // {
    //     path: tagConfigurationPath + '/by-brand/:brandId',
    //     name: 'configurationByBrand',
    //     component: () => import('@/views/tag-configuration/ConfigurationByBrand.vue'),
    //     meta: {
    //         hideTopNavbar: true,
    //         hideBreadCrumb: true,
    //         public: false,
    //     }
    // },
    // {
    //     path: tagManualPath + '/by-brand/:brandId',
    //     name: 'configurationByBrandManual',
    //     component: () => import('@/views/tag-manual/ConfigurationByBrandManual.vue'),
    //     meta: {
    //         hideTopNavbar: true,
    //         hideBreadCrumb: true,
    //         public: false,
    //     }
    // },

    // User configuration
    {
        path: '/user-configuration',
        name: 'UserConfiguration',
        component: () => import('@/views/userConfiguration/UserConfiguration.vue'),
        meta: {
            breadCrumb: [
                {
                    text: 'Cuenta'
                }
            ]
        }
    },
    {
        path: `/user-configuration${forgotPassword}`,
        component: () => import('@/views/forgot-password/ForgotPassword.vue'),
        meta: {
            hideTopNavbar: true,
            hideBreadCrumb: true,
            public: false,
        }
    },
    // Miscelaneas
    {
        path: unauthorizedPath,
        name: 'Unauthorized',
        component: import('@/views/misc/Unauthorized.vue'),
        meta: {
            requiresAnnonymousUser: true,
        }
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: () => import('@/views/misc/Forbidden.vue'),
    },
    {
        path: '/not-allowed',
        name: 'NotAllowed',
        component: () => import('@/views/misc/NotAllowed.vue'),
    },
    {
        path: '*',
        component: () => import('@/views/misc/PageNotFound'),
        name: 'NotFound',
    },
    // {
    //     path: '/configuration',
    //     name: 'Configuration',
    //     component: () => import('@/views/configuration/Configuration.vue'),
    //     meta: {
    //         breadCrumb: [
    //             {
    //                 text: 'Configuración'
    //             }
    //         ]
    //     }
    // },
    // {
    //     path: '/help',
    //     name: 'Help',
    //     component: () => import('@/views/help/Help.vue'),
    //     meta: {
    //         breadCrumb: [
    //             {
    //                 text: 'Ayuda'
    //             }
    //         ]
    //     }
    // },
];

// By default, all routes require auth
routes.forEach(r => {
    if (!r.meta?.requiresAnnonymousUser && !r.meta?.requiresAuth && !r.meta?.public) {
        if (!r.meta) {
            r.meta = {};
        }
        r.meta.requiresAuth = true;
        delete r.meta.requiresAnnonymousUser;
        delete r.meta.public;
    }
});

const router = new VueRouter({
    routes
});

// const hasAuthorities = (user, matched) => matched
//     .map(record => [record.meta.requiredPermissions || [], record.meta.requiredRoles || []])
//     .every(([permissions, roles]) => user.hasAllPermissions(permissions) && user.hasAllRoles(roles));

router.beforeEach((to, from, next) => {
    const requireAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresAnnonymousUser = to.matched.some(record => record.meta.requiresAnnonymousUser);
    const resolveAuth = requireAuth || requiresAnnonymousUser;
    const fn = () => {
        if (authService.forceLogin) {
            if (requireAuth && to.path !== loginPath) {
                return next({ path: loginPath, query: { t: to.fullPath } });
            }
        } else if (requiresAnnonymousUser && !authService.forceLogin) {
            return next({ path: getDefaultPath(authService.user) });
        } else if (to.matched.some(record => record.meta.requiresAuth) && !authService.validUser) {
            return next({ name: 'Unauthorized', query: { t: to.fullPath } });
        } else if (to.matched.some(record => record.meta.requiredPermissions || record.meta.requiredRoles) && !authService.user.hasAuthorities(to.matched)) {
            return next({ name: 'Forbidden', query: { t: to.path } });
        }
        next();
    };

    if (resolveAuth) {
        authService.initialize();
    } else {
        return next();
    }

    if (authService.initialized) {
        return fn();
    }

    authService.$watch('initialized', initialized => {
        if (initialized && !authService.error) {
            return fn();
        }
    });
});

export default router;
