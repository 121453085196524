<template>
  <v-app>
    <NavbarMenu
      v-if="!forceLogin"
      @logout="logout"
    />
    <v-main>
      <bread-crumb v-if="!forceLogin" />
      <router-view />
    </v-main>
    <Notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      multi-line
    />
  </v-app>
</template>

<script>
import Notification from '@/components/mainStructure/Notification.vue';
import NavbarMenu from '@/components/mainStructure/NavbarMenu.vue';
import BreadCrumb from '@/components/mainStructure/BreadCrumb.vue';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

export default {
    name: 'App',

    components: {
        NavbarMenu,
        Notification,
        BreadCrumb,
    },

    data: () => ({
    //
    }),
    computed: {
        ...mapState([
            'fullScreenError',
            'isLoading',
            'isInitStatus',
            'authService',
        ]),
        ...mapGetters([
            'currentUser',
            'forceLogin',
        ]),
        notifications () {
            return this.$notifications.notifications.map((e, i) => ({ ...e, i }));
        },
    },
    created () {
        this.initStatus();
    },
    methods: {
        ...mapActions([
            'initStatus',
            'logout',
        ]),
        ...mapMutations([
            'showNotification',
            'dismissNotification',
        ])
    }
};
</script>

<style>
html {
  overflow-y: auto
}

i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light::before{
  cursor: pointer;
}

</style>
