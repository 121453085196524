class ServiceError extends Error {
    constructor (error) {
        super(error);
        this.date = new Date();
        this.details = [];
        this.code = error.code;
        this.message = error.message;
    }
}

module.exports = ServiceError;
