import Vue from 'vue';

let currentId = 1;

class Notification {
    constructor (type, text, timeout = -1) {
        this.active = false;
        this.type = type || null;
        this.text = text || '';
        this.timeout = timeout;
        this.id = currentId++;
    }

    setActive (active) {
        this.active = active;
    }

    setType (type) {
        this.type = type;
    }

    setText (text) {
        this.text = text;
    }

    setTimeout (timeout) {
        this.timeout = timeout;
    }
}

const notifications = new Vue({
    data () {
        return {
            notifications: [],
        };
    },
    methods: {
        show (type, text, timeout = -1) {
            const notification = new Notification(type, text, timeout);
            this.notifications.unshift(notification);
            notification.setActive(true);
            if (notification.timeout !== -1) {
                setTimeout(() => this.dismiss(notification), notification.timeout);
            }
        },
        showError (text, timeout = -1) {
            this.show('error', text, timeout);
        },
        showInfo (text, timeout = -1) {
            this.show('info', text, timeout);
        },
        showSuccess (text, timeout = -1) {
            this.show('success', text, timeout);
        },
        showWarning (text, timeout = -1) {
            this.show('warning', text, timeout);
        },
        dismiss (notification) {
            this.notifications = this.notifications.filter(e => e.id !== notification.id);
        },
    },
});

const plugin = {
    install () {
        Vue.notifications = notifications;
        Vue.prototype.$notifications = notifications;
    }
};

export default plugin;
