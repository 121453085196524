import Vue from 'vue';
import User from './User';
import axios from 'axios';
import ServiceError from '@/errors/ServiceError';

const httpClient = axios.create({
    baseURL: '/auth',
});

export default new Vue({
    data: () => ({
        loading: false,
        isAuthenticated: false,
        user: null,
        error: null,
        initialized: false,
        forceLogin: false,
        refreshing: false,
        tokenValue: localStorage.getItem('token') ?? '',
    }),
    computed: {
        validUser () {
            return this.initialized && !this.forceLogin && !this.error;
        },
        token: {
            get: function () {
                return this.tokenValue;
            },
            set: function (token) {
                this.tokenValue = token;
                localStorage.setItem('token', token);
            }
        },
    },
    methods: {
        logout () {
            this.isAuthenticated = false;
            this.user = {};
        },
        async initialize () {
            if (this.initialized) {
                return;
            }
            let initializeError = false;
            this.loading = true;
            const token = this.token;
            if (token) {
                try {
                    // TODO modificar cuando el back devuelva el user en el validate token
                    await httpClient.get('/validate-token', { headers: { Authorization: `${token}` } });
                    this.user = new User(JSON.parse(localStorage.getItem('user')));
                } catch (err) {
                    console.log('err', err);

                    try {
                        await this.refreshToken();
                    } catch (error) {
                        initializeError = true;
                    }
                }
            }
            if (initializeError || !token) {
                this.forceLogin = true;
            }
            this.loading = false;
            this.initialized = true;
        },
        async refreshToken () {
            if (this.refreshing) {
                return new Promise(resolve => {
                    this.$watch('refreshing', refreshing => {
                        if (!refreshing) {
                            return resolve();
                        }
                    });
                });
            }
            this.refreshing = true;
            const body = { refreshToken: localStorage.getItem('refreshToken') };
            const response = (await httpClient.post('/refresh-token', body)).data;
            this.user = new User(response);
            this.token = response.token.accessToken;
            localStorage.setItem('refreshToken', response.token.refreshToken);
            this.isAuthenticated = true;
            this.forceLogin = false;
            this.refreshing = false;
        },
        async login (credentials) {
            this.loading = true;
            try {
                const response = await httpClient.post('/login', credentials);
                if (response.status === 200) {
                    const user = response.data;
                    this.user = new User(user);
                    this.token = user.token.accessToken;
                    localStorage.setItem('refreshToken', user.token.refreshToken);
                    localStorage.setItem('user', JSON.stringify(user));
                    this.isAuthenticated = true;
                    this.forceLogin = false;
                }
                return response;
            } catch (error) {
                this.forceLogin = true;
                console.error('Error al realizar el inicio de sesión:', error);
                throw new ServiceError({ code: error.response?.status, message: error.response?.data?.message });
            } finally {
                this.loading = false;
            }
        }

    },
});
