class User {
    constructor (userInfo) {
        this.firstName = userInfo.firstName;
        this.lastName = userInfo.lastName;
        this.username = userInfo.username;
        this.phone = userInfo.phone;

        let permissions = userInfo.permissions ?? [];
        this.permissions = permissions.map(p => p.toLowerCase());

        let roles = userInfo.roles ?? [];
        this.roles = roles.map(r => r.toLowerCase());
    }

    get displayName () {
        return `${this.firstName} ${this.lastName}`;
    }

    hasPermission (permission) {
        return this.permissions.includes(permission.toLowerCase());
    }

    hasAllPermissions (permissions) {
        return permissions.every(p => this.hasPermission(p));
    }

    hasRole (role) {
        return this.roles.includes(role.toLowerCase());
    }

    hasAllRoles (roles) {
        return roles.every(r => this.hasRole(r));
    }

    hasAuthorities (matchedRecords) {
        return matchedRecords
            .map(record => [record.meta.requiredPermissions || [], record.meta.requiredRoles || []])
            .every(([permissions, roles]) => this.hasAllPermissions(permissions) && this.hasAllRoles(roles));
    }
}

module.exports = User;
