<template>
  <v-snackbar
    v-model="snackbar"
    top
    right
    :multi-line="multiLine"
    :color="type"
    :timeout="timeout"
    outlined
  >
    {{ text }}
    <template #action="{ attrs }">
      <v-btn
        icon
        :color="type"
        small
        v-bind="attrs"
        @click.prevent="dismiss"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        notification: {
            type: Object,
            default: undefined
        },
        multiLine: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        snackbar: true,
    }),
    computed: {
        text () {
            return this.notification.text;
        },
        type () {
            return this.notification.type;
        },
        timeout () {
            return this.notification.timeout;
        },
        margin () {
            return (this.notification.i * 80) + 'px';
        },
    },
    watch: {
        snackbar (val) {
            val || this.dismiss();
        }
    },
    methods: {
        dismiss () {
            this.$notifications.dismiss(this.notification);
        },
    }
};
</script>

<style scoped>

</style>
