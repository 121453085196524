class Utils {
    getLocale () {
        return navigator.language || navigator.browserLanguage || (navigator.languages || ['es-AR'])[0];
    }

    getToken () {
        return localStorage.getItem('token');
    }

    // getToday () {
    //     return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10);
    // }

    // isTodayBetween (since, until) {
    //     if (until != null) {
    //         return ((this.getToday() >= new Date(since).toISOString().slice(0, 10)) && (this.getToday() <= new Date(until).toISOString().slice(0, 10)));
    //     } else {
    //         return (this.getToday() >= new Date(since).toISOString().slice(0, 10));
    //     }
    // }
    getToday () {
        return new Date().toISOString().slice(0, 10);
    }

    isTodayBetween (since, until) {
        const today = new Date();
        const sinceDate = new Date(since);

        if (until) {
            const untilDate = new Date(until);
            return (today >= sinceDate) && (today <= untilDate);
        } else {
            return today >= sinceDate;
        }
    }

    isDateLessThanToday (date) {
        // return (this.getToday() >= new Date(date).toISOString().slice(0, 10));
        return (new Date(date).getFullYear() <= 2020);
    }

    getDateToPicker (date) {
        return new Date(date).toISOString().slice(0, 10);
    }

    getTodayString (separator = '/') {
        const [year, month, day] = this.getToday().split('-');
        return `${day}${separator}${month}${separator}${year}`;
    }

    formatDate (date) {
        const [year, month, day] = new Date(date).toISOString().slice(0, 10).split('-');
        return `${day}/${month}/${year}`;
    }

    formatDateTime (date) {
        const value = new Date(date);
        const day = ('' + value.getDate()).padStart(2, '0');
        const month = ('' + value.getMonth() + 1).padStart(2, '0');
        const year = value.getFullYear();
        const hour = ('' + value.getHours()).padStart(2, '0');
        const minute = ('' + value.getMinutes()).padStart(2, '0');
        const second = ('' + value.getSeconds()).padStart(2, '0');
        return `${day}/${month}/${year}-${hour}:${minute}:${second}`;
    }

    getTodayISOString () {
        return new Date(this.getToday()).toISOString();
    }

    getQueryDate (filters) {
        if (!filters || !Object.keys(filters).length) return '';

        let query = '';
        if (filters.startDate) {
            query += `start_date=${filters.startDate.toISOString()}`;
        }
        if (filters.endDate) {
            query += `&end_date=${filters.endDate.toISOString()}`;
        }
        return query;
    }

    getQueryPage (filters, otherFilters) {
        if (!filters || !Object.keys(filters).length) return '';

        let query = '';
        if ('pagination' in filters) {
            if (filters.pagination.page) {
                query += `page=${filters.pagination.page}`;
            }
            if (filters.pagination.size) {
                query += `&size=${filters.pagination.size}`;
            }
        }
        if (!query) {
            return query;
        }

        return otherFilters.length ? `&${query}` : query;
    }

    getQueryTypes (filters) {
        if (!filters || !filters?.length) return '';
        return `&types=${filters.join(',')}`;
    }

    getQueryProducts (filters) {
        if (!filters || !filters?.length) return '';
        return `&products=${filters.join(',')}`;
    }

    getQueryTrackIds (filters) {
        if (!filters || !filters?.length) return '';
        return `&track_id=${filters.join(',')}`;
    }

    saveFile (data, fileName) {
        // create file link in browser's memory
        const href = URL.createObjectURL(data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); // or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    isDigit (e) {
        let charCode = e.charCode;
        if (!(charCode >= 48 && charCode <= 57) || (!/^[0-9]+$/.test(charCode))) {
            e.preventDefault();
        }
    }
}

export default new Utils();
