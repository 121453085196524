<template>
  <div>
    <v-navigation-drawer
      v-if="!$route.meta.hideNavbar"
      v-model="drawer"
      v-click-outside="closeDrawer"
      app
      :mini-variant.sync="mini"
      color="primary"
    >
      <v-list-item>
        <v-list-item-icon>
          <v-icon
            color="white"
            @click.stop="mini = !mini"
          >
            <template v-if="!mini">
              mdi-backburger
            </template>
            <template v-else>
              mdi-menu-open
            </template>
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-img
            max-width="130"
            src="@/assets/membrete.svg"
          />
        </v-list-item-content>
      </v-list-item>

      <!-- Lista inicial -->
      <v-list dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          :to="item.route"
          class="my-3"
        >
          <v-list-item-icon>
            <v-icon color="white">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="white--text subtitle-1 font-weight-regular">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-spacer />

      <!-- Lista final-->
      <template #append>
        <v-list dense>
          <!-- <v-list-item
                        key="Ayuda"
                        link
                        to="/help"
                    >
                        <v-list-item-icon>
                            <v-icon color="white">mdi-help-circle</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title class="white--text subtitle-1 font-weight-regular">
                               Ayuda
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->

          <v-list-item
            key="Cerrar sesión"
            link
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon color="white">
                mdi-logout-variant
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text subtitle-1 font-weight-regular">
                Cerrar sesión
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      v-if="!$route.meta.hideNavbar && !$route.meta.hideTopNavbar"
      app
      color="white"
      elevation="0"
      class="mx-6"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click.stop="drawer = !drawer"
      >
        <v-icon
          color="black"
          @click.stop="drawer = !drawer"
        >
          mdi-menu-open
        </v-icon>
      </v-app-bar-nav-icon>

      <span class="headline hidden-sm-and-down">¡Hola {{ currentUser.firstName }}!</span>

      <v-spacer />
      <!-- <v-btn icon class="mx-2">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-btn icon class="mx-2">
                <v-icon>mdi-bell</v-icon>
            </v-btn> -->
      <v-btn
        icon
        class="mx-2"
        @click="goToUserConfiguration"
      >
        <v-avatar
          color="lightPrimary"
          size="48"
        >
          <span class="primary--text headline">{{ initialLetter }}</span>
        </v-avatar>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { userConfigurationPath } from '@/router/index';

export default {
    name: 'NavbarMenu',
    data: () => ({
        drawer: true,
        mini: true,
    }),
    computed: {
        ...mapGetters(['currentUser', 'validUser', 'loadingAuth']),
        ...mapState(['menuItems']),
        initialLetter () {
            return this.currentUser.firstName.charAt(0);
        }
    },
    methods: {
        logout () {
            this.$emit('logout');
        },
        closeDrawer () {
            this.mini = true;
        },
        toggleDrawer () {
            if (this.currentUser) {
                this.drawer = !this.drawer;
            }
        },
        goToUserConfiguration () {
            if (this.$route.path !== userConfigurationPath) {
                this.$router.push(userConfigurationPath);
            }
        },
    },
};
</script>
