<template>
  <v-breadcrumbs
    v-if="!$route.meta.hideBreadCrumb"
    :items="breadCrumbs"
    class="mx-6 pt-1"
  >
    <template #item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        class="grey--text text--darken-3"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
    computed: {
        breadCrumbs () {
            if (typeof this.$route.meta.breadCrumb === 'function') {
                return this.$route.meta.breadCrumb.call(this, this.$route);
            }
            return this.$route.meta.breadCrumb;
        },
    },
};
</script>
