import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2768b5',
                secondary: '#424242',
                accent: '#82b1ff',
                error: '#ff5252',
                info: '#2196f3',
                success: '#4caf50',
                warning: '#FB8C00',
                background: '#ececec',
                black: '#2e2e29',
                white: '#ffffff',
                grey: '#e2e2e2',
                tableSelected: '#DEEFFF',
                lightPrimary: '#DEEFFF',
            }
        },
        options: { customProperties: true },
    },
});
